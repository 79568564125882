import { api, jsonApi } from '../axios'
import qs from 'qs'

export default {
  // 限时BA列表
  getBaList({
    page,
    count,
    register_time_start,
    register_time_end,
    check_status,
    type,
    mobile,
    id
  } = {}) {
    return api.get('/employee/ba/get_ba', {
      params: {
        page,
        count,
        register_time_start,
        register_time_end,
        check_status,
        type,
        mobile,
        id
      }
    })
  },
  updateStatus({
    ids,
    check_status
  } = {}) {
    return api.post('/employee/ba/update_apply', qs.stringify({
      ids,
      check_status
    }))
  },
  getOrderList({
    page,
    count,
    create_time_start,
    create_time_end,
    order_status,
    issue_status,
    unusual_status,
    receipt_number,
    update_time_start,
    update_time_end,
    receipt_from,
    check_status,
    type,
    mobile
  } = {}) {
    return api.get('/employee/receipt/receipt_list', {
      params: {
        page,
        count,
        create_time_start,
        create_time_end,
        order_status,
        issue_status,
        unusual_status,
        receipt_number,
        update_time_start,
        update_time_end,
        receipt_from,
        check_status,
        type,
        mobile
      }
    })
  },
  getOrderDetail(receipt_id) {
    return api.get('/employee/receipt/receipt_info', {
      params: {
        receipt_id
      }
    })
  },
  getReceiptBAList() {
    return api.get('/employee/ba/get_pass_ba')
  },
  updateOrderStatus({
    receipt_ids,
    check_status,
    employee_remark
  } = {}) {
    return api.get('/employee/receipt/update_receipt', {
      params: {
        receipt_ids,
        check_status,
        employee_remark
      }
    })
  },
  getReceiptGoodsList({
    goods_name,
    goods_number,
    market_price,
  } = {}) {
    return api.get('/employee/goods/goods_list', {
      params: {
        goods_name,
        goods_number,
        market_price
      }
    })
  },
  uploadData({ data_url, data_type, deal_type }) {
    return api.post('/employee/dataTask/import_data', qs.stringify({ data_url, data_type, deal_type }))
  },
  updateReceipt({
    receipt_number,
    ba_id,
    buy_time,
    shop_id,
    pic_url,
    receipt_from,
    goods_info,
    receipt_id,
    real_cost,
    pic_urls
  } = {}) {
    return api.post('/employee/receipt/add_receipt', qs.stringify({
      receipt_number,
      ba_id,
      buy_time,
      shop_id,
      pic_url,
      receipt_from,
      goods_info,
      receipt_id,
      real_cost,
      pic_urls
    }))
  },
  // 获取门店列表
  getShopList({
    page,
    count,
    shop_name,
    shop_number,
  } = {}) {
    return api.get('/employee/shop/shop_list', {
      params: {
        page,
        count,
        shop_name,
        shop_number,
      }
    })
  },
  // 获取省份列表
  getAddressProvinceList() {
    return api.get('/common/area/province')
  },
  // 获取城市列表
  getAddressCityList({
    province_id,
  } = {}) {
    return api.get('/common/area/city', {
      params: {
        province_id,
      }
    })
  },
  // 获取区列表
  getAddressDistrictList({
    city_id,
  } = {}) {
    return api.get('/common/area/district', {
      params: {
        city_id,
      }
    })
  },
  addOrEditShop({
    shop_id,
    province_id,
    city_id,
    district_id,
    shop_name,
    shop_number,
    detail_address,
    shop_belong_manage_id,
  } = {}) {
    return api.post('/employee/shop/add_shop', qs.stringify({
      shop_id,
      province_id,
      city_id,
      district_id,
      shop_name,
      shop_number,
      detail_address,
      shop_belong_manage_id,
    }))
  },
  // 获取商品列表
  getGoodsList({
    page,
    count,
    goods_name,
    goods_number,
  } = {}) {
    return api.get('/employee/goods/goods_list', {
      params: {
        page,
        count,
        goods_name,
        goods_number,
      }
    })
  },
  // 添加编辑商品
  addOrEditGoods({
    goods_name,
    goods_number,
    market_price,
    goods_id,
    return_amount,
    return_num,
    return_extend_num,
    return_extend_amount,
    status
  } = {}) {
    return api.post('/employee/goods/add_goods', qs.stringify({
      goods_name,
      goods_number,
      market_price,
      goods_id,
      return_amount,
      return_num,
      return_extend_num,
      return_extend_amount,
      status
    }))
  },
  // 获取员工列表
  getEmployeeList({
    role_id,
    count,
    login_name
  } = {}) {
    return api.get('/employee/employee/employee_list', {
      params: {
        role_id,
        count,
        login_name
      }
    })
  },
  getBaShop(ba_id) {
    return api.get('/employee/ba/get_ba_info', {
      params: {
        ba_id
      }
    })
  },
  getEmployeeById(employee_id) {
    console.log(employee_id)
    return api.get('/employee/employee/info', {
      params: {
        employee_id
      }
    })
  },
  getShop(shop_id) {
    return api.get('/employee/shop/shop_detail', {
      params: {
        shop_id
      }
    })
  },
  getRegionList() {
    return api.get('/employee/region/region_list')
  },
  exportList({
    register_time_start,
    register_time_end,
    check_status,
    mobile
  } = {}) {
    return api.get('/employee/ba/export_ba', {
      params: {
        register_time_start,
        register_time_end,
        check_status,
        mobile
      }
    })
  },
  exportOrderList({
    create_time_start,
    create_time_end,
    update_time_start,
    update_time_end,
    check_status,
    receipt_number,
    receipt_from
  } = {}) {
    return api.get('/employee/receipt/export_data', {
      params: {
        create_time_start,
        create_time_end,
        update_time_start,
        update_time_end,
        check_status,
        receipt_number,
        receipt_from
      }
    })
  },
  modifyUser({
    name,
    role_id,
    login_name,
    relate_region,
    mobile,
    pwd,
    status,
    employee_id
  } = {}) {
    return api.post('/employee/employee/add_employee', qs.stringify({
      name,
      role_id,
      relate_region,
      login_name,
      mobile,
      pwd,
      status,
      employee_id
    }))
  },

  changeUserPassword({
    pwd,
    employee_id
  } = {}) {
    return api.post('/employee/employee/update_pwd', qs.stringify({
      pwd,
      employee_id
    }))
  },

  getIntegralList({
    page,
    count,
    create_time_start,
    create_time_end,
    operation
  } = {}) {
    return api.get('/employee/integral/integral_list', {
      params: {
        page,
        count,
        create_time_start,
        create_time_end,
        operation
      }
    })
  },

  getIntegralMoneyList({
    page,
    count,
    create_time_start,
    create_time_end,
    operation_status
  } = {}) {
    return api.get('/employee/integral/integral_money', {
      params: {
        page,
        count,
        create_time_start,
        create_time_end,
        operation_status
      }
    })
  },

  integralMoneyUpdate({
    money_ids,
    operation_status
  } = {}) {
    return api.post('/employee/integral/integral_money_update', qs.stringify({
      money_ids,
      operation_status
    }))
  },
  // 获取学习资料列表
  getResourceList({
    page,
    count,
    status,
    title,
  } = {}) {
    return api.get('/employee/lm/lm_list', {
      params: {
        page,
        count,
        status,
        title,
      }
    })
  },
  // 添加学习资料
  addOrEditResource({
    title,
    content,
    prize_num,
    threshold,
    id,
    url,
    status,
    type
  } = {}) {
    return api.post('/employee/lm/lm_edit', qs.stringify({
      title,
      content,
      prize_num,
      threshold,
      id,
      url,
      status,
      type
    }))
  },
  getResourceInfo({
    id
  } = {}) {
    return api.get('/employee/lm/lm_detail', {
      params: {
        id
      }
    })
  },
  // 获取预算列表
  getBudgetList({
    page,
    count,
    year,
    brand_cn,
    sku,
  } = {}) {
    return api.get('/employee/budget/list', {
      params: {
        page,
        count,
        year,
        brand_cn,
        sku,
      }
    })
  },
  // 添加编辑预算
  addOrEditBudget({
    brand_cn,
    sku,
    sku_code,
    id,
    sku_pos,
    sku_cost,
    sku_kp_price,
    sku_kp_ratio,
    year,
    num,
    status
  } = {}) {
    return api.post('/employee/budget/edit', qs.stringify({
      brand_cn,
      sku,
      sku_code,
      id,
      sku_pos,
      sku_cost,
      sku_kp_price,
      sku_kp_ratio,
      year,
      num,
      status
    }))
  },
  getSkuMonthList({
    page,
    count,
    month,
    sku_code,
  } = {}) {
    return api.get('/employee/budgetMonth/list', {
      params: {
        page,
        count,
        month,
        sku_code,
      }
    })
  },
  editSkuMonth({
    sku_code,
    num,
    unit_pos_price,
    cost_price,
    kp_price,
    shop_num,
    month,
    status,
    id
  } = {}) {
    return api.post('/employee/budgetMonth/edit', {
      sku_code,
      num,
      unit_pos_price,
      cost_price,
      kp_price,
      shop_num,
      month,
      status,
      id
    })
  },
  // 下载预算月度分解
  exportBudget({
    year,
    type,
  } = {}) {
    return api.post('/employee/budget/export', qs.stringify({
      year,
      type
    }))
  },
  // 下载预算月度分解
  exportTemp({
    year
  } = {}) {
    return api.post('/employee/budget/export_temp', qs.stringify({
      year
    }))
  },

  getLedgerCashList({
    page,
    count,
    month,
    c_type,
    type,
  } = {}) {
    return api.get('/employee/budgetLedgerCash/list', {
      params: {
        page,
        count,
        month,
        c_type,
        type
      }
    })
  },
  addOrEditLC({
    type,
    c_type,
    price,
    month,
    status,
    id
  } = {}) {
    return api.post('/employee/budgetLedgerCash/edit', {
      type,
      c_type,
      price,
      month,
      status,
      id
    })
  },

}
